import axios from "axios";

const apiRequest = async (method, url, data = null) => {
  try {
    const config = {
      method: method,
      url: url,
      data: data,
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    console.error("API Request Error:", error);
    throw error;
  }
};

export default apiRequest;
