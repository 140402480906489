import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useMap } from 'react-leaflet';
import './zoomControlBtn.scss';

const ZoomControlBtn = () => {
  const map = useMap();

  const zoomIn = () => {
    map.setZoom(map.getZoom() + 1); // Increase zoom level
  };

  const zoomOut = () => {
    map.setZoom(map.getZoom() - 1); // Decrease zoom level
  };

  return (
    <Box
      className='leaflet-right'
      sx={{
        position: 'absolute',
        bottom: '100px',
        zIndex: '888',
        right: '10px',
      }}
    >
      <Box className='leaflet-control zoomBtnWrapper'>
        <IconButton
          className='zoomInBtn'
          onClick={zoomIn}
          aria-label='Zoom In'
          sx={{ color: '#08652e' }}
        >
          <Add />
        </IconButton>
        <IconButton
          className='zoomOutBtn'
          onClick={zoomOut}
          aria-label='Zoom Out'
          sx={{ color: '#08652e' }}
        >
          <Remove />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ZoomControlBtn;
