import a from './a.png';
import b from './b.png';
import c from './c.png';
import d from './d.png';
import e from './e.png';
import f from './f.png';
import g from './g.png';
import h from './h.png';
import i from './i.png';
import j from './j.png';
import k from './k.png';
import l from './l.png';
import m from './m.png';
import n from './n.png';
import o from './o.png';
import p from './p.png';
import q from './q.png';
import r from './r.png';
import s from './s.png';
import t from './t.png';
import u from './u.png';
import v from './v.png';
import w from './w.png';
import x from './x.png';
import y from './y.png';
import z from './z.png';

export const MARKERS = [
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k,
  l,
  m,
  n,
  o,
  p,
  q,
  r,
  s,
  t,
  u,
  v,
  w,
  x,
  y,
  z,
];
