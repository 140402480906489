import React, { useState, useEffect } from 'react';
import './App.scss';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Header from './components/Header';
import MapComponent from './components/MapContainer';
import SearchOptions from './components/SearchOption';
import StationDetails from './components/StationDetails';
import { getPromotionLinks } from './store/actions/stationActions';
import { useDispatch } from 'react-redux';
import CookieDisclaimer from './components/CookieDisclaimer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrintableRoutes from './components/PrintableRoutes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TripPlanner from './components/TripPlanner';
import { MAP_VIEWS } from './store/constants/mapViewConstants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './components/Footer';
import ThemeRegistry from './components/ThemeRegistry';
import { clearLocalStorage } from './lib/helper';
import { DEFAULT_FILTER } from './store/constants';

function App() {
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState({ lat: 38.8951, lng: -77.0364 });
  const [selectedStation, setSelectedStation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER);
  console.log('App ~ filterValues:', filterValues);
  const [position, setPosition] = useState(null);
  const [sourceCity, setSourceCity] = useState(
    JSON.parse(window?.localStorage?.getItem('SOURCE_CITY') || `{}`)
  );
  const [destinationCity, setDestinationCity] = useState(
    JSON.parse(window?.localStorage?.getItem('DESTINATION_CITY') || `{}`)
  );
  const [routeDetails, setRouteDetails] = useState();
  console.log('App ~ routeDetails:', routeDetails);
  const [destinations, setDestinations] = useState(
    JSON.parse(window?.localStorage?.getItem('DESTINATIONS') || `[]`)
  );
  const [mapView, setMapView] = useState(MAP_VIEWS.normal);

  const [tripPlannerOpen, setTripPlannerOpen] = useState(false);

  const handleGetDirectionsClick = (station) => {
    if (destinationCity || destinations?.length) {
      return toast.warn(
        'You cannot get directed instead you can add this to your trip.'
      );
    }
    setDestinationCity({
      lat: station?.Latitude,
      lng: station?.Longitude,
      name: station?.StationName,
    });
    setTripPlannerOpen(true);
    handleShowLocation();
  };

  const handleLocationSelect = (location) => {
    setMapCenter(location);
    setPosition(location);
  };

  const handleStationSelect = (station) => {
    setPosition(null);
    setSelectedStation(station);
  };

  const handleOpenModal = (station) => {
    setOpenModal(true);
  };

  const handleClearTrip = () => {
    if (window?.localStorage) {
      clearLocalStorage();
    }
    setDestinationCity(null);
    setSourceCity(null);
    setRouteDetails(null);
    setDestinations([]);

    setMapCenter(null);
    setPosition(null);
  };

  const handleShowLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (destinationCity && sourceCity) {
          handleClearTrip();
        }
        setTripPlannerOpen(false);
        if (result.state === 'denied') {
          toast.error('Please enable location services to use this feature.');
        } else {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const { latitude, longitude } = pos.coords;
              const userLocation = { lat: latitude, lng: longitude };
              setSourceCity(userLocation);
              setMapCenter(userLocation);
              setPosition(userLocation);
              toast.success('Location retrieved successfully!');
            },
            () => {
              toast.error('Unable to retrieve location.');
            }
          );
        }
      });
    } else {
      toast.error('Geolocation is not supported by your browser.');
    }
  };

  const handleTripPlanner = async () => {
    try {
      if (!tripPlannerOpen) {
        setMapCenter(null);
        setPosition(null);
      }
      setTripPlannerOpen(!tripPlannerOpen);
    } catch (err) {
      console.error('handleTripPlanner ~ err:', err);
    }
  };

  const handleAddToTrip = (station) => {
    try {
      if (!destinationCity) {
        setDestinationCity({
          lat: station?.Latitude,
          lng: station?.Longitude,
          name: station?.StationName,
        });
        setTripPlannerOpen(true);

        if (!sourceCity) {
          handleShowLocation();
        }
        return;
      }

      if (destinationCity) {
        setDestinations([
          ...destinations,
          {
            lat: station?.Latitude,
            lng: station?.Longitude,
            name: station?.StationName,
          },
        ]);
      }
    } catch (err) {
      console.error('handleAddToTrip ~ err:', err);
    }
  };

  useEffect(() => {
    dispatch(getPromotionLinks());
  }, [dispatch]);

  useEffect(() => {
    if (destinations?.length && window.localStorage) {
      window?.localStorage?.setItem(
        'DESTINATIONS',
        JSON.stringify(destinations)
      );
    }

    if (sourceCity && window?.localStorage) {
      window?.localStorage?.setItem('SOURCE_CITY', JSON.stringify(sourceCity));
    }
    if (destinationCity && window?.localStorage) {
      window?.localStorage?.setItem(
        'DESTINATION_CITY',
        JSON.stringify(destinationCity)
      );
    }
  }, [destinations, sourceCity, destinationCity]);

  return (
    <ThemeProvider theme={ThemeRegistry}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Box className='App'>
                <ToastContainer />
                <Header
                  mapCenter={mapCenter}
                  onLocationSelect={handleLocationSelect}
                  handleShowLocation={handleShowLocation}
                  setSourceCity={setSourceCity}
                  setDestinationCity={setDestinationCity}
                  setRouteDetails={setRouteDetails}
                  handleTripPlanner={handleTripPlanner}
                  setMapView={setMapView}
                  tripPlannerOpen={tripPlannerOpen}
                />
                <Box className='map-section'>
                  <MapComponent
                    filterValues={filterValues}
                    center={mapCenter}
                    onStationSelect={handleStationSelect}
                    onOpenModal={handleOpenModal}
                    position={position}
                    handleShowLocation={handleShowLocation}
                    sourceCity={sourceCity}
                    destinationCity={destinationCity}
                    setRouteDetails={setRouteDetails}
                    routeDetails={routeDetails}
                    setSourceCity={setSourceCity}
                    destinations={destinations}
                    mapView={mapView}
                  />
                  <SearchOptions
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    routeDetails={routeDetails}
                  />

                  {tripPlannerOpen && (
                    <TripPlanner
                      sourceCity={sourceCity}
                      destinationCity={destinationCity}
                      setDestinationCity={setDestinationCity}
                      setSourceCity={setSourceCity}
                      onClose={() => {
                        setTripPlannerOpen(false);
                      }}
                      destinations={destinations}
                      setRouteDetails={setRouteDetails}
                      routeDetails={routeDetails}
                      setDestinations={setDestinations}
                    />
                  )}
                  <StationDetails
                    station={selectedStation}
                    isOpenModal={openModal}
                    setOpenModal={setOpenModal}
                    onGetDirectionsClick={handleGetDirectionsClick}
                    handleAddToTrip={handleAddToTrip}
                  />
                </Box>
                <Footer />
                {/* <CookieDisclaimer /> */}
              </Box>
            }
          />
          <Route
            path='/printable-routes'
            element={
              <PrintableRoutes
                destinations={destinations}
                routeDetails={routeDetails}
                sourceName={sourceCity?.name}
                destinatioinName={destinationCity?.name}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
