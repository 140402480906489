import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import './PrintableRoutes.scss';
import { Box, Button, Typography } from '@mui/material';

import Logo from '../../assets/img//logo_stati_loc.png';
import RoutingOnly from '../TripPlanner/onlyRouting';
import { MAP_VIEWS } from '../../store/constants/mapViewConstants';
import ZoomedMap from '../ZoomedMap/index';
import ZoomControlBtn from '../ZoomControlBtn';
import { DIRECTION_ICONS } from '../../lib/helper';

const PrintableRoutes = ({
  routeDetails,
  destinatioinName,
  sourceName,
  destinations,
}) => {
  const handlePrint = () => {
    window.print();
  };

  if (!routeDetails || routeDetails.length === 0) {
    return <p>No route details available.</p>;
  }

  return (
    <Box className='backsheet'>
      <Box className='frontsheet'>
        <Box className='print-container'>
          <Box
            className='header-logo'
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={Logo} alt='' />
            <Typography variant='h6' sx={{ fontSize: 20 }}>
              {new Date().toDateString()}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              <Typography
                sx={{
                  fontSize: 22,
                  textAlign: 'left',
                  fontWeight: 700,
                  maxWidth: 750,
                }}
              >
                Route : {`"${sourceName}" To "${destinatioinName}"`}
              </Typography>
              <Box>
                <Button
                  variant='contained'
                  color='success'
                  onClick={handlePrint}
                >
                  Print Route
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              {routeDetails.length > 0 && (
                <Box key={routeDetails[0].index}>
                  <Typography
                    className='estimation'
                    sx={{ display: 'inline-block' }}
                  >
                    <span>Estimated distance:</span> {routeDetails[0].distance}
                  </Typography>
                  <Typography
                    className='estimation'
                    sx={{ display: 'inline-block', marginLeft: 10 }}
                  >
                    <span>Estimated time:</span> {routeDetails[0].duration}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              <MapContainer
                center={[
                  routeDetails[0]?.startLocation?.lat,
                  routeDetails[0]?.startLocation?.lng,
                ]}
                zoom={1}
                style={{ height: '500px', width: '100%', position: 'relative' }}
              >
                {MAP_VIEWS.normal}
                <RoutingOnly
                  sourceCity={{
                    lat: routeDetails[0]?.startLocation?.lat,
                    lng: routeDetails[0]?.startLocation?.lng,
                  }}
                  destinationCity={{
                    lat: routeDetails[0]?.endLocation?.lat,
                    lng: routeDetails[0]?.endLocation?.lng,
                  }}
                  destinations={destinations}
                />
                {/* <ZoomControlBtn /> */}
              </MapContainer>
            </Box>
            <Box className='routesArea' sx={{ display: 'flex' }}>
              <Box className='routesDetailContent'>
                {routeDetails.map((route) => (
                  <Box key={route.index}>
                    <Box>
                      <Box
                        sx={{
                          padding: '45px 0 23px 0',
                          paddingLeft: '10%',
                          fontSize: '16px',
                          fontWeight: '700',
                          borderBottom: '1px solid #E6E6E6',
                        }}
                      >
                        Start from {sourceName}
                      </Box>
                      {route.steps.map((step) => (
                        <Typography
                          variant='body1'
                          className='listStep'
                          key={step.step}
                        >
                          {DIRECTION_ICONS[step?.directions]}
                          <span className='listStepInstructrion'>
                            {step.text}
                          </span>
                          <span className='direction-separator'>
                            <span className='directions-line'></span>
                            <span className='directions-distance-time'>
                              <span className='listStepDistance'>
                                {step.distance}
                              </span>
                              <span className='listStepDuration'>
                                {step.time}
                              </span>
                            </span>
                          </span>
                        </Typography>
                      ))}
                      <Box
                        sx={{
                          padding: '25px 0 45px 0',
                          paddingLeft: '10%',
                          fontSize: '16px',
                          fontWeight: '700',
                          borderBottom: '1px solid #E6E6E6',
                        }}
                      >
                        Location end at {destinatioinName}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  // alignItems: "end",
                  marginTop: '10px',
                }}
              >
                <Box>
                  <Box sx={{ marginTop: '40px' }}>
                    <ZoomedMap
                      destinations={destinations}
                      routeDetails={routeDetails}
                      focusPoint={[
                        routeDetails[0]?.startLocation?.lat,
                        routeDetails[0]?.startLocation?.lng,
                      ]}
                    />
                  </Box>
                  <Box sx={{ marginTop: '40px' }}>
                    <MapContainer
                      center={[
                        routeDetails[0]?.startLocation?.lat,
                        routeDetails[0]?.startLocation?.lng,
                      ]}
                      zoom={1}
                      style={{
                        height: '280px',
                        width: '310px',
                        position: 'relative',
                      }}
                    >
                      {MAP_VIEWS.normal}
                      <RoutingOnly
                        sourceCity={{
                          lat: routeDetails[0]?.startLocation?.lat,
                          lng: routeDetails[0]?.startLocation?.lng,
                        }}
                        destinationCity={{
                          lat: routeDetails[0]?.endLocation?.lat,
                          lng: routeDetails[0]?.endLocation?.lng,
                        }}
                        destinations={destinations}
                      />
                    </MapContainer>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: '90px' }}>
                  {destinations?.length > 0 &&
                    destinations.map((des, index) => (
                      <ZoomedMap
                        destinations={destinations}
                        routeDetails={routeDetails}
                        focusPoint={[des?.lat, des?.lng]}
                      />
                    ))}
                  <ZoomedMap
                    destinations={destinations}
                    routeDetails={routeDetails}
                    focusPoint={[
                      routeDetails[0]?.endLocation?.lat,
                      routeDetails[0]?.endLocation?.lng,
                    ]}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrintableRoutes;
