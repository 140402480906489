import React, { useState } from "react";
import "./CookieDisclaimer.scss";
import { Box, Typography } from "@mui/material";

const CookieDisclaimer = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    document.cookie = "cookieConsent=true; path=/";
  };

  if (accepted || document.cookie.includes("cookieConsent=true")) return null;

  return (
    <Box className="cookie-disclaimer">
      <Box className="cookie-disclaimer-content">
        <Typography>
          This website uses cookies to ensure you get the best experience on our
          website.
        </Typography>
        <button onClick={handleAccept}>Accept</button>
      </Box>
    </Box>
  );
};

export default CookieDisclaimer;
