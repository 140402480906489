import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Modal } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { handleContactForm } from '../../store/actions/stationActions';
import { useDispatch } from 'react-redux';
import './stationDetails.scss';
import * as Yup from 'yup';
import { EmailShareButton } from 'react-share';

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .test(
      'no-leading-spaces',
      'Name should not start with a space',
      (value) => value && value[0] !== ' '
    )
    .matches(
      /^[a-zA-Z ]*$/,
      'Name should not contain special characters and numbers'
    ).max(50, 'Name should not exceed 50 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .matches(emailRegExp, 'Invalid email format')
    .required('Email is required')
    .max(254, 'Name should not exceed 254 characters'),
  recipientName: Yup.string()
    .required('Recipient name is required')
    .test(
      'no-leading-spaces',
      'Recipient Name should not start with a space',
      (value) => value && value[0] !== ' '
    )
    .matches(
      /^[a-zA-Z ]*$/,
      'Name should not contain special characters and numbers'
    ).max(50, 'Name should not exceed 50 characters'),
  recipientEmail: Yup.string()
    .email('Invalid email address')
    .required('Recipient email is required')
    .max(254, 'Name should not exceed 254 characters'),
  stationInfo: Yup.string().required('Station info is required'),
});

export default function ContactModal({
  openContactModal,
  handleCloseContactModal,
  station,
  IsEmail,
}) {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

    const capitalizeFirstLetter = (str) => {
      return str
        ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        : "";
    };

  let stationInfo = '';
  if (IsEmail) {
      stationInfo = `Station info \n\nstation: ${capitalizeFirstLetter(
        station?.StationName
      )}\nAddress: ${capitalizeFirstLetter(
        station?.StationAddress
      )} ${capitalizeFirstLetter(station?.StationCity)}, ${
        (station?.StationStatus === "Active" && "Operational") ||
        station?.StationStatus
      } ${station?.StationZip}\nOpen hours: ${
        station?.HoursOpen?.replace(/none|None|;/gi, " ") || "N/A"
      }\nAccepts: ${
        capitalizeFirstLetter(
          station?.PaymentTypesAccepted?.replace(/;/g, ", ")
        ) || "N/A"
      }`;
  } else {
   stationInfo = "Sincerely,\n\nclean energy station locator\nhttp://www.cnglngstations.com";
  }

  const handleSubmitContactForm = (values) => {
    const payload = {
      EmailAddress: values?.email,
      EmailBody: values?.stationInfo,
      EmailName: values?.name,
      RecipientEmail: values?.recipientEmail,
      RecipientName: values?.recipientName,
    };
    dispatch(handleContactForm({ payload }))
      .then(async (response) => {
        if (response?.status === 201 || response?.status === 200) {
          setSuccess('Contact form sucessFully!');
          handleCloseContactModal();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setError(error.message);
      });
  };

  return (
    <Modal
      className='contact-modal'
      open={openContactModal}
      onClose={handleCloseContactModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box className='contact-modal-body'>
        <Formik
          initialValues={{
            name: '',
            email: '',
            recipientName: '',
            recipientEmail: '',
            stationInfo: stationInfo,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmitContactForm(values);
          }}
        >
          {({ values, handleChange, errors, touched, isValid, dirty }) => (
            <Form>
              <Typography variant='h3'>Contact Form</Typography>
              <Field
                name='name'
                as={TextField}
                label='Name'
                className='contact-input'
                variant='outlined'
                onChange={handleChange}
                value={values.name}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#000',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#08652e !important',
                    },
                  },
                  '& .MuiInputLabel-outlined': {
                    color: '#08652e !important',
                  },
                }}
              />
              <Field
                name='email'
                as={TextField}
                label='Email'
                className='contact-input'
                variant='outlined'
                onChange={handleChange}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#000',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#08652e !important',
                    },
                  },
                  '& .MuiInputLabel-outlined': {
                    color: '#08652e !important',
                  },
                }}
              />
              <Field
                name='recipientName'
                as={TextField}
                label='Recipient name'
                className='contact-input'
                variant='outlined'
                onChange={handleChange}
                value={values.recipientName}
                error={touched.recipientName && Boolean(errors.recipientName)}
                helperText={touched.recipientName && errors.recipientName}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#000',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#08652e !important',
                    },
                  },
                  '& .MuiInputLabel-outlined': {
                    color: '#08652e !important',
                  },
                }}
              />
              <Field
                name='recipientEmail'
                as={TextField}
                label='Recipient email'
                className='contact-input'
                variant='outlined'
                onChange={handleChange}
                value={values.recipientEmail}
                error={touched.recipientEmail && Boolean(errors.recipientEmail)}
                helperText={touched.recipientEmail && errors.recipientEmail}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#000',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#08652e !important',
                    },
                  },
                  '& .MuiInputLabel-outlined': {
                    color: '#08652e !important',
                  },
                }}
              />
              <Field
                name='stationInfo'
                as={TextField}
                multiline
                rows={4}
                className='contact-input'
                value={values.stationInfo}
                error={touched.stationInfo && Boolean(errors.stationInfo)}
                helperText={touched.stationInfo && errors.stationInfo}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#000',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#08652e !important',
                    },
                  },
                  '& .MuiInputLabel-outlined': {
                    color: '#08652e !important',
                  },
                }}
              />
              {success && <Typography variant='h3'>{success}</Typography>}
              {error && <Typography variant='h3'>{error}</Typography>}
              <Box className='contact-footer'>
                <Box className='contact-footer-btn'>
                  <button
                    variant='outlined'
                    onClick={handleCloseContactModal}
                    type='button'
                    className='cancelButton'
                  >
                    Cancel
                  </button>
                  <button
                    className='emailSendButton'
                    // variant="contained"
                    disabled={!(isValid && dirty)}
                    onClick={() => {
                      const emailSubject = `Station info: ${station?.StationName}`;
                      const emailBody = `Station info: \n\n ${stationInfo}`;
                      const emailTo = values.recipientEmail;
                      const emailFrom = values.email;

                      // Create a mailto link
                      const mailtoLink = `mailto:${emailTo}?subject=${encodeURIComponent(
                        emailSubject
                      )}&body=${encodeURIComponent(
                        emailBody
                      )}&from=${emailFrom}`;

                      // Open the default email client with pre-filled details
                      window.location.href = mailtoLink;
                    }}
                  >
                    {/* <EmailShareButton
                    className='emailSendButton'
                      type="submit"
                      url="http://www.cnglngstations.com"
                      subject={`Station Info: ${station?.StationName}`}
                      body={`Station Info: \n\n ${stationInfo}`}
                      disabled={!(isValid && dirty)}
                      separator="\n"
                    >
                    </EmailShareButton> */}
                    Send
                  </button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
