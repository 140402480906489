import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import 'leaflet-control-geocoder';
import { toast } from 'react-toastify';
import { useMap } from 'react-leaflet';
import { fetchLocationName } from '../../lib/helper';
import { MARKERS } from '../../assets/markers/markers';

L.Marker.prototype.options.icon = L.icon({
  // iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconUrl: MARKERS[0],

  iconSize: [25, 41],
  iconAnchor: [20, 41],
});

const Routing = ({
  sourceCity,
  destinationCity,
  setRouteDetails,
  setSourceCity,
  destinations,
}) => {
  const map = useMap();

  const getIcon = (index) => {
    const customIconStart = L.icon({
      iconUrl: MARKERS[index],
      iconSize: [60, 60],
      iconAnchor: [30, 60],
      //   popupAnchor: [0, -30],
    });

    return customIconStart;
  };

  useEffect(() => {
    if (!map) return;
    // Initialize geocoder
    const geocoder = L.Control.Geocoder.nominatim();

    if (sourceCity?.lat !== undefined && destinationCity?.lat !== undefined) {
      const routingControl = L.Routing.control({
        waypoints: [
          L.latLng(parseFloat(sourceCity.lat), parseFloat(sourceCity.lng)),

          ...(destinations?.map((destination) =>
            L.latLng(parseFloat(destination.lat), parseFloat(destination.lng))
          ) || []),
          L.latLng(
            parseFloat(destinationCity.lat),
            parseFloat(destinationCity.lng)
          ),
        ],
        createMarker: function (i, waypoint, n) {
          return L.marker(waypoint.latLng, {
            icon: getIcon(i),
          });
        },
        routeWhileDragging: true,
        lineOptions: {
          styles: [{ color: '#6FA1EC', weight: 4 }],
        },
        show: false,
        showAlternatives: false,
        addWaypoints: true,
        fitSelectedRoutes: true,
        geocoder: geocoder,
        reverseWaypoints: false,
      })
        .on('routesfound', async function (e) {
          const routes = e.routes;
          if (routes.length > 0) {
            toast.success('Route found!', { position: 'top-right' });

            // Extract route details and store them in state
            const routeInfo = [];
            // const routeInfo = routes.map(async (route, index) =>
            for (let i = 0; i < routes?.length; i++) {
              const route = routes[i];
              const index = i;
              const locationName = await fetchLocationName(
                route?.coordinates[0]?.lat,
                route?.coordinates[0]?.lng
              );

              setSourceCity({ ...sourceCity, name: locationName });
              routeInfo.push({
                index: index + 1,
                startLocation: {
                  ...route?.coordinates[0],

                  name: 'Source Location',
                },
                endLocation: {
                  ...route?.coordinates[route?.coordinates?.length - 1],

                  name: 'Destination Location',
                },
                distance:
                  (route.summary.totalDistance / 1000).toFixed(2) + ' km',
                duration:
                  (route.summary.totalTime / 60).toFixed(2) + ' minutes',
                steps: route.instructions.map((instr, i) => ({
                  step: i + 1,
                  text: instr.text,
                  distance: (instr.distance / 1000).toFixed(2) + ' km',
                  directions: instr?.direction,
                })),
              });
            }

            // Set route details in state

            setRouteDetails([...routeInfo]);
          } else {
            toast.error('No route found!', { position: 'top-right' });
          }
        })
        .on('routingerror', function (e) {
          toast.error('Location not found or routing error!', {
            position: 'top-right',
          });
        })
        .addTo(map);

      return () => map.removeControl(routingControl);
    }
  }, [
    map,
    sourceCity?.lat,
    sourceCity?.lng,
    destinationCity,
    setRouteDetails,
    destinations,
  ]);

  return null;
};

export default Routing;
