import { createTheme } from "@mui/material/styles";

const ThemeRegistry = createTheme({
  typography: {
    h1: { fontFamily: "Proxima Nova, sans-serif" },
    h2: { fontFamily: "Proxima Nova, sans-serif" },
    h3: { fontFamily: "Proxima Nova, sans-serif" },
    h4: { fontFamily: "Proxima Nova, sans-serif" },
    h5: { fontFamily: "Proxima Nova, sans-serif" },
    h6: { fontFamily: "Proxima Nova, sans-serif" },
    body1: { fontFamily: "Proxima Nova, sans-serif" },
    body2: { fontFamily: "Proxima Sera, serif", color: "#585458" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Proxima Nova';
          src: url('./assets/fonts/ProximaNova-Regular.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Proxima Sera';
          src: url('./assets/fonts/ProximaSera-Regular.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
      `,
    },
  },
});

export default ThemeRegistry;
