import { TileLayer } from 'react-leaflet';

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_BOX_ACCESS_KEY;

export const MAP_VIEWS = {
  normal: (
    <TileLayer
      url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
      tileSize={512}
      zoomOffset={-1}
      maxZoom={18}
    />
  ),

  arial: (
    <TileLayer
      url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
      tileSize={512}
      zoomOffset={-1}
      maxZoom={18}
    />
  ),
  traffic: (
    <TileLayer
      url={`https://api.mapbox.com/styles/v1/mapbox/navigation-day-v1/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
      tileSize={512}
      zoomOffset={-1}
      maxZoom={18}
    />
  ),
};
